<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage">牧户：{{ muHuDetailData.household_name || '牧户名称' }}</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <!-- 饼图 -->
        <div class="left_box">
          <div class="title flex_start text_center flex_center">
            <div class="w_100 main_color p_10 border_box fs_18 fw_bold">牲畜数量</div>

            <div class="w_40 h_30 mon_text lh_25 color_fff fs_16 main_color">
              (ᠮᠠᠯ᠎ᠤᠨ ᠲᠣᠭ᠎ᠠ)
            </div>
          </div>

          <!-- <zhu-zhuang-tu class="chart" :value="data1" /> -->

          <div class="p_20 color_fff fw_bold">
            <div class="mb_10 flex_start">
              <div class="w_25_p text_left">
                成年牛&nbsp; <span class="font_number mr_30 main_color">{{ muHuDetailData.adult_cattle_num || 0 }}</span>
              </div>
              <div class="w_25_p text_left">
                犊牛&nbsp; <span class="font_number mr_30 main_color">{{ muHuDetailData.calf_cattle_num || 0 }}</span>
              </div>
              <div class="w_25_p text_left">
                育肥牛&nbsp; <span class="font_number mr_30 main_color">{{ muHuDetailData.fatting_cattle_num || 0 }}</span>
              </div>
              <div class="w_25_p text_left">
                检疫牛&nbsp; <span class="font_number mr_30 main_color">{{ muHuDetailData.vaccinee || 0 }}</span>
              </div>
              <!-- <div class="w_20_p text_left">
                出售牛&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div> -->
            </div>

            <div class="mb_10 flex_start">
              <div class="w_25_p text_left">
                山羊&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div>
              <div class="w_25_p text_left">
                绵羊&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div>
              <div class="w_25_p text_left">
                出售羊&nbsp; <span class="font_number mr_30 main_color">{{ muHuDetailData.fatting_cattle_num || 0 }}</span>
              </div>
            </div>

            <div class="mb_10 flex_start">
              <div class="w_25_p text_left">
                青猪&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div>
              <div class="w_25_p text_left">
                育肥猪&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div>
              <div class="w_25_p text_left">
                老母猪&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div>
              <div class="w_25_p text_left">
                猪仔&nbsp; <span class="font_number mr_30 main_color">{{ muHuDetailData.fatting_cattle_num || 0 }}</span>
              </div>
            </div>

            <div class="mb_10 flex_start">
              <div class="w_25_p text_left">
                马&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div>
              <div class="w_25_p text_left">
                驴&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div>
              <div class="w_25_p text_left">
                狗&nbsp; <span class="font_number mr_30 main_color">0</span>
              </div>
              <div class="w_25_p text_left">
                鸡鸭&nbsp; <span class="font_number mr_30 main_color">{{ muHuDetailData.fatting_cattle_num || 0 }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="left_box">
          <div class="title flex_start text_center flex_center">
            <div class="w_100 main_color p_10 border_box fs_18 fw_bold">机械数量</div>

            <div class="w_40 h_30 mon_text lh_25 color_fff fs_16 main_color">
              (ᠮᠧᠬᠠᠨᠢᠭ᠎ᠤᠨ ᠲᠣᠭ᠎ᠠ)
            </div>
          </div>

          <bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <!-- <hao-rao-qin-hamlet /> -->

          <div class="avatar">
            <img src="../../../../assets/images/user/user-avatar.svg" alt="">
          </div>
        </div>

        <div class="context_box">
          <div class="flex_row_between pr_20">
            <div class="h_24 lh_24 mb_3">所在镇（苏木）： <span class="main_color">{{ muHuDetailData.village }}</span> </div>
            <div class="h_24 lh_24 mb_3">草场面积：<span class="main_color font_number fw_bold ls_2">578 ㎡</span> </div>
          </div>
          
          <div class="flex_row_between pr_20">
            <div class="h_24 lh_24 mb_3">所在嘎查（村）： <span class="main_color">{{ muHuDetailData.hamlet }}</span></div>
            <div class="h_24 lh_24 mb_3">种植面积：<span class="main_color font_number fw_bold ls_2">214 ㎡</span> </div>
          </div>
          
          <div class="flex_row_between pr_20">
            <div class="h_24 lh_24 mb_3">
              电话：<span class="main_color fw_bold font_number ls_2">{{ formatPhone(muHuDetailData.phone_number) }}</span>
            </div>
          </div>
          
          <!-- <div class="h_24 lh_24 mb_3">所在镇（苏木）： </div>
          <div class="h_24 lh_24 mb_3">所在嘎查（村）： </div>
          <div class="h_24 lh_24 mb_3">电话： </div> -->
        </div>

        <div class="bottom_box">
          <div class="title flex_start text_center flex_center">
            <div class="w_100 main_color p_10 border_box fs_18 fw_bold">草料数量</div>

            <div class="w_40 h_30 mon_text lh_25 color_fff fs_16 main_color">
              (ᠡᠪᠡᠰᠦ ᠪᠣᠷᠳᠤᠭᠠᠨ᠎ᠤ ᠲᠣᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠶ᠎ᠡ)
            </div>
          </div>
          <ban-bing-tu :value="data3" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="cattle_list">
          <div class="title flex_start text_center flex_center">
            <div class="w_100 main_color p_10 border_box fs_18 fw_bold">牲畜列表</div>

            <div class="w_40 h_30 mon_text lh_25 color_fff fs_16 main_color">
              (ᠮᠠᠯ᠎ᠤᠨ ᠬᠦᠰᠦᠨᠦᠭ)
            </div>
          </div>

          <div class="list_box p_20">
            <div 
              class="list_item" 
              v-for="(item, index) in muHuDetailData.cattle_list" :key="index"
              @click="showOxDetail(index)"
            >
              <div class="img_box w_30_p h_180">
                <!-- 2023 7 30 周日 为了演示 （牛太廋） -->
                <div class="img full" v-if="muHuDetailData.household_name == '温都苏'">
                  <img class="full border_radius_10 response_img fit_contain" :src="testOxImg[index].oxImg" alt="">
                </div>

                <div class="img full" v-else>
                  <img 
                    class="full border_radius_10 response_img" 
                    v-if="item.photo_set && item.photo_set.length"
                    :src="item.photo_set[0].file" 
                    alt=""
                  >
                  <img class="full border_radius_10 response_img" v-else :src="item.img_url" alt="">
                </div>
              </div>

              <div class="info_box w_70_p flex_column_around pl_20">
                <div class="h_35 lh_35 fs_22">耳标 <span class="font_number main_color">{{ item.ear_num}}</span> </div>
                <div class="h_35 lh_35 fs_22">品种 {{ item.breed_type}} </div>
                <div class="h_35 lh_35 fs_22">状态 {{ item.current_state}} </div>
                <div class="h_35 lh_35 fs_22">毛色 {{ item.fur_color}} </div>
                <div class="h_35 lh_35 fs_22">性别 {{ item.sex}} </div>
              </div>
            </div>
          </div> 

        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import { getMuHuDetail } from '../../../../config/api';
  import HaoRaoQinHamlet from '../maps/da-ban-hamlet/hao-rao-qin.vue';
  import BingTu from '../charts/bing-tu.vue';
  import BanBingTu from '../charts/ban-bing-tu.vue';
  import ZhuZhuangTu from '../charts/zhu-zhuang-tu.vue';
  
  export default {
    name: 'showPage1',
    components: { BaLinYouQiMap, HaoRaoQinHamlet, BingTu, BanBingTu, ZhuZhuangTu },
    data() {
      return {
        activeIndex: 0,
        makidoName: '',
        data1: {},
        data2: {},
        data3: {},
        muHuDetailData: {},

        testOxImg: [
          { oxImg: require('@/assets/images/WenDuSu-OxImg/img1.jpg') },
          { oxImg: require('@/assets/images/WenDuSu-OxImg/img2.jpg') },
          { oxImg: require('@/assets/images/WenDuSu-OxImg/img3.jpg') },
          { oxImg: require('@/assets/images/WenDuSu-OxImg/img5.jpg') },
          { oxImg: require('@/assets/images/WenDuSu-OxImg/img4.jpg') },
          { oxImg: require('@/assets/images/WenDuSu-OxImg/img6.jpg') },
          { oxImg: require('@/assets/images/WenDuSu-OxImg/img7.jpg') },
          { oxImg: require('@/assets/images/WenDuSu-OxImg/img8.jpg') },
        ]
      }
    },
    mounted() {
      let muHuId = this.$route.params.val

      // 通过牧户 id 获取 牧户详情信息
      this.getMuHuDetail(muHuId)

      setTimeout(() => {
        this.initChart()
      }, 500)
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: {
            text: "牲畜数量",
            left: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '头',
          x: ['成年牛', '犊牛', '育肥牛', '检疫牛', '其它牲畜'],
          y: [this.muHuDetailData.adult_cattle_num, this.muHuDetailData.calf_cattle_num, this.muHuDetailData.fatting_cattle_num, this.muHuDetailData.vaccinee, this.muHuDetailData.other_cattle_num ]
        }

        this.data2 = {
          title: '机械数量',
          unit: '辆',
          data: [
            {name: "小型拖拉机", value: 2, unit: ' '},
            {name: "大型拖拉机", value: 1, unit: ' '},
            {name: "打草机", value: 1, unit: ' '},
            {name: "播种机", value: 1, unit: ' '},
            {name: "家用车辆", value: 2, unit: ' '}
          ]
        }

        this.data3 = {
          title: '草料数量',
          unit: '吨',
          data: [
            {name: "甘草", value: 10, unit: ' '},
            {name: "饲料", value: 25, unit: ' '},
            {name: "配料", value: 15, unit: ' '}
          ]
        }
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      // 获取牧户详情
      async getMuHuDetail(id) {
        let params = { id: id }
        let res = await getMuHuDetail(params)
        this.muHuDetailData = res.data
      },

      // 前往 牛详情
      showOxDetail(index) {
        let id = this.muHuDetailData.cattle_list[index].id
        console.log(id);
        this.$router.push({
          path: "/bigscreen/oxDetail" + id
        })
      },

      // 格式化手机号 175 **** 4435
      formatPhone(val) {
        return val.substring(0, 3) + '****' + val.substring(7, 12)
      }

    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .w_20_p { font-size: 18px; } 
  }
</style>
